<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">学员信息审核</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">审核学员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="addlist" style="width: 70%">
              <div>
                <h3>
                  <span>基本信息</span>
                </h3>
                <div class="form-box">
                  <el-form
                    ref="baseform"
                    label-width="7rem"
                    class="form"
                    :rules="rules"
                    :model="baseform"
                  >
                    <el-form-item label="证件类型" prop="idType">
                       <el-select
                        v-model="baseform.idType"
                        placeholder="请选择证件类型"
                        size="small"
                        clearable
                        disabled
                      >
                        <el-option
                          v-for="item in EduUserCardList"
                          :key="item.dictCode"
                          :label="item.dictValue"
                          :value="item.dictCode"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                     <el-form-item label="证件号码" prop="idcard" :rules="{
                        required: true,
                        validator: baseform.idType == '10' ? this.$validateIdCard :'',
                        message:'请输入证件号',
                        trigger: 'blur',
                      }">
                      <el-input
                          disabled
                        v-model="baseform.idcard"
                        size="small"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="userName">
                      <el-input
                          disabled
                        v-model="baseform.userName"
                        size="small"
                      ></el-input>
                    </el-form-item>

                    <el-form-item label="性别" prop="sex" size="small">
                      <el-radio v-model="baseform.sex" label="1" disabled
                        >男</el-radio
                      >
                      <el-radio v-model="baseform.sex" label="2" disabled
                        >女</el-radio
                      >
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                      <el-input
                          disabled
                        v-model="baseform.mobile"
                        size="small"
                        style="width: 70%; float: left"
                      ></el-input>
                      <el-button
                        size="small"
                        class="bgc-bv"
                        style="margin-left: 30px"
                        v-if="show1"
                        @click="xgmobile"
                        >修改</el-button
                      >
                    </el-form-item>
                    <el-form-item
                      label="民族"
                      prop="nation"
                      :rules="{
                        required: false,
                        message: '请选择民族',
                        trigger: 'change',
                      }"
                    >
                      <el-select
                        v-model="baseform.nation"
                        placeholder="请选择民族"
                        size="small"
                        disabled
                        clearable
                      >
                        <el-option
                          v-for="item in nationList"
                          :key="item.dictCode"
                          :label="item.dictValue"
                          :value="item.dictCode"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="住址"
                      prop="address"
                      disabled
                      :rules="{
                        required: false,
                        message: '请输入住址',
                        trigger: 'blur',
                      }"
                    >
                      <el-input
                        v-model="baseform.address"
                        size="small"
                        maxlength="50"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="工作区域" prop="workingArea">
                      <el-cascader
                        clearable
                        disabled
                        filterable
                        v-model="baseform.workingArea"
                        :options="areatreeList"
                        :props="propsarea"
                        size="small"
                      />
                    </el-form-item>
                    <el-form-item label="工作单位" prop="workUnit">
                      <el-input
                        v-model="baseform.workUnit"
                        size="small"
                        maxlength="50"
                        disabled
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="学员类型" prop="studentType">
                      <el-select
                          disabled
                        v-model="baseform.studentType"
                        placeholder="请选择学员类型"
                        size="small"
                      >
                        <el-option
                          v-for="item in studengTypeList"
                          :key="item.dictCode"
                          :label="item.dictValue"
                          :value="item.dictCode"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="出生年月"
                      prop="birthday"
                      :rules="{
                        required: false,
                        message: '请选择出生年月日',
                        trigger: 'change',
                      }"
                    >
                      <el-date-picker
                        size="small"
                        disabled
                        v-model="baseform.birthday"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择出生年月日"
                      >
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item
                      label="证件有效期"
                      prop="idCardValidity"
                      :rules="{
                        required: false,
                        message: '请输入证件有效期',
                        trigger: 'blur',
                      }"
                    >
                      <el-input
                          disabled
                        v-model="baseform.idCardValidity"
                        size="small"
                        placeholder="请输入证件有效期"
                      ></el-input>
                      <p style="color: #f46173">
                        示例：2010.06.10-2015.06.10或2010.06.10-长期
                      </p>
                    </el-form-item>
                    <el-form-item
                    v-if="baseform.idType == '10'"
                      label="证件照片"
                      prop="headPathUrl"
                      :rules="{
                        required:false,
                        message: '请上传身份证信息',
                        trigger: 'change',
                      }"
                    >
                      <div style="display: flex">
                        <div style="width: 50%">
                          <el-upload
                            :on-change="handleAvatarSuccessCardFront"
                            :before-upload="$beforeAvatarUpload"
                            :http-request="$requestMine"
                            :show-file-list="false"
                            class="img-el-upload"
                            action
                            accept="image/png, image/gif, image/jpg, image/jpeg"
                          >
                            <el-image
                              :src="
                                baseform.idCardFront ||
                                require('@/assets/develop.png')
                              "
                              fit="contain"
                              class="imgCenter"
                            ></el-image>
                          </el-upload>
                          <p class="PImgtitle">人像面</p>
                        </div>
                        <div style="width: 50%; margin-left: 15px">
                          <el-upload
                              disabled
                            :on-change="handleAvatarSuccessCardBack"
                            :before-upload="$beforeAvatarUpload"
                            :http-request="$requestMine"
                            :show-file-list="false"
                            class="img-el-upload"
                            action
                            accept="image/png, image/gif, image/jpg, image/jpeg"
                          >
                            <el-image
                              :src="
                                baseform.idCardBack ||
                                require('@/assets/develop.png')
                              "
                              fit="contain"
                              class="imgCenter"
                            ></el-image>
                          </el-upload>
                          <p class="PImgtitle">国徽面</p>
                        </div>
                      </div>
                    </el-form-item>
                      <el-form-item
                       v-if="baseform.idType == '20'"
                      label="证件照片"
                      prop="headPathUrl"
                      :rules="{
                        required:false,
                        message: '请上传证件信息',
                        trigger: 'change',
                      }"
                    >
                      <div style="display: flex">
                        <div style="width: 50%">
                          <el-upload
                              disabled
                            :on-change="handleAvatarSuccessPassportPhoto"
                            :before-upload="$beforeAvatarUpload"
                            :http-request="$requestMine"
                            :show-file-list="false"
                            class="img-el-upload"
                            action
                            accept="image/png, image/gif, image/jpg, image/jpeg"
                          >
                            <el-image
                              :src="
                                baseform.passportPhotoUrl ||
                                require('@/assets/develop.png')
                              "
                              fit="contain"
                              class="imgCenter"
                            ></el-image>
                          </el-upload>
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item label="电子邮件" prop="email">
                      <el-input
                          disabled
                        v-model="baseform.email"
                        size="small"
                      ></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="所属公司" prop="compName">
                      <el-input v-model="baseform.compName"></el-input>
                    </el-form-item>-->
                    <el-form-item
                      label="所属公司"
                      prop="compId"
                      class="company"
                    >
                      <el-select
                          disabled
                        size="small"
                        v-model="baseform.compId"
                        filterable
                        clearable
                        placeholder="请至少输入两个字搜索"
                        remote
                        :remote-method="getCompanyList"
                      >
                        <el-option
                          v-for="item in companyList"
                          :key="item.compId"
                          :label="item.compName"
                          :value="item.compId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="所属部门" prop="department">
                      <el-input
                        v-model="baseform.department"
                        disabled
                        size="small"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="文化水平">
                      <el-select
                        v-model="baseform.educationLevel"
                        placeholder="请选择文化水平"
                        size="small"
                        disabled
                      >
                        <el-option
                          v-for="item in education"
                          :key="item.dictCode"
                          :label="item.dictValue"
                          :value="item.dictCode"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      :rules="{
                        required: true,
                        message: '认证状态不能为空',
                        trigger: 'blur',
                      }"
                      label="认证状态"
                    >
                      <el-select
                        v-model="baseform.authentication"
                        placeholder="请选择认证状态"
                        size="small"
                        disabled
                      >
                        <el-option
                          v-for="item in authentication"
                          :key="item.dictCode"
                          :label="item.dictValue"
                          :value="item.dictCode"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="人脸照片"
                      prop="headPathUrl"
                      :rules="{
                        required:
                          baseform.authentication == '10' ? false : true,
                        message: '请上传人脸照片',
                        trigger: 'change',
                      }"
                    >
                      <el-upload
                        :on-change="handleAvatarSuccess2"
                        :before-upload="$beforeAvatarUpload"
                        :http-request="$requestMine"
                        :show-file-list="false"
                        class="img-el-upload"
                        action
                        disabled
                        accept="image/png, image/gif, image/jpg, image/jpeg"
                      >
                        <el-image
                          :src="
                            baseform.headPathUrl ||
                            require('@/assets/develop.png')
                          "
                          fit="contain"
                          class="imgCenter"
                        ></el-image>
                      </el-upload>
                    </el-form-item>
                    <el-form-item label="证件照">
                      <div class="flexdc">
                        <div class="df idPhoto" style="margin-bottom: 10px">
                          <!-- <img
                            class
                            style="width:135px;height:155px;margin-right:10px"
                            src="../../assets/id_photo_example.png"
                            alt
                          />-->
                          <div
                            @mouseleave="showDel = false"
                            @mouseover="showDel = true"
                          >
                            <div
                              v-show="baseform.idPhotoKey && showDel"
                              class="flexdcc"
                            >
                              <i
                                class="el-icon-delete"
                                style="color: #fff; font-size: 20px"
                                @click="delIdPhoto"
                              ></i>
                            </div>
                            <img
                              style="width: 135px; height: 189px"
                              :src="
                                baseform.idPhoto ||
                                require('@/assets/id_photo_example.png')
                              "
                              alt
                            />
                          </div>
                        </div>
                        <div class="pic_upload">
                          <el-upload
                            :on-change="handleAvatarSuccessIdPhoto"
                            :before-upload="beforeIdPhotoUpload"
                            :http-request="$requestMine"
                            :show-file-list="false"
                            class="img-el-upload"
                            action
                            disabled
                            accept="image/png, image/gif, image/jpg, image/jpeg"
                          >
                            <el-button
                              size="small"
                              class="bgc-bv"
                              style="margin-left: 30px"
                              >点击上传</el-button
                            >
                          </el-upload>
                        </div>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="btn-box">
                <el-button type="primary" @click="doCancel" class="bgc-bv"
                >取 消</el-button
                >
                <el-button type="primary" class="bgc-bv" @click="doEditSave"
                  >审 核</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- vueCropper 剪裁图片实现-->
    <el-dialog
      title="图片剪裁"
      :visible="dialogVisible"
      :before-close="docropperCencal"
      class="vueCropper_dialog"
      top="15px"
    >
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
            :mode="option.mode"
          ></vueCropper>
        </div>
      </div>
      <p class="flexcb" style="margin: 5px 0 15px">
        <span style="color: #e90000"
          >如果您上传的图片像素过高，可以将鼠标放到图片上滑动鼠标滚轮进行缩放。</span
        >
        <el-upload
          :on-change="handleAvatarSuccessIdPhoto"
          :before-upload="beforeIdPhotoUpload"
          :http-request="$requestMine"
          :show-file-list="false"
          class="img-el-upload"
          style="display: inline"
          action
          accept="image/png, image/gif, image/jpg, image/jpeg"
        >
          <span
            style="color: #5c6be8; cursor: pointer; text-decoration: underline"
            >重新上传</span
          >
        </el-upload>
      </p>
      <div class="cropper_tip">
        <h3>上传照片要求</h3>
        <div class="flexcb">
          <div class="flexac">
            <span class="tip_index">1</span>
            <span class="tip_text">人像清晰，神态自然无明显变形</span>
          </div>
          <div class="flexac">
            <span class="tip_index">2</span>
            <span class="tip_text">人像占照片尺寸2/3 纯色背景</span>
          </div>
          <div class="flexac">
            <span class="tip_index">3</span>
            <span class="tip_text">35*49mm或413*578像素 二寸照片最佳</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="docropperCencal">取 消</el-button>
        <el-button class="bgc-bv" @click="finish">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="审核学员"
        :visible.sync="dialogVisibleAudit"
        width="30%"
        @close="handleCloseAudit"
    >
      <div>
        <el-form
            ref="auditForm"
            label-width="8rem"
            label-position="left"
            class="form"
            :rules="auditRules"
            :model="auditForm"
        >
          <el-form-item label="是否审核通过:" prop="audited">
            <el-select v-model="auditForm.audited" placeholder="请选择" size="small" >
              <el-option
                  label="是"
                  :value="true"
              ></el-option>
              <el-option
                  label="否"
                  :value="false"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <el-input
                type="textarea"
                placeholder="请输入备注"
                v-model="auditForm.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <span style="display: flex; justify-content: flex-end">
          <el-button @click="handleCloseAudit">取 消</el-button>
          <el-button class="bgc-bv" @click="auditUser()"
          >确 定</el-button
          >
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { VueCropper } from "vue-cropper";
export default {
  name: "editStudent",
  components: {
    VueCropper,
  },
  data() {
    return {
      dialogVisible: false,
      cropperUrl: "",
      // cropperFile: "",
      opendialog: true,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 413, // 默认生成截图框宽度
        autoCropHeight: "100%", // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1.4], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: "contain",
      },
      showDel: false,
      showtip: false,
      monthShow: false, // 身份证有效期
      idcard: "",
      idcardObj: {},
      // 基本信息
      baseform: {
        idType:'10', //证件类型
        mobile: "", // 手机号
        sex: "", // 性别
        email: "", // 邮件
        idcard: "", // 身份证
        userName: "", // 用户名
        educationLevel: "", // 文化水平
        authentication: "", // 专业
        headPathUrl: "", //图片地址
        headPath: "", // key
        idPhoto: "", // 证件照
        idPhotoKey: "", // 证件照
        nation: "", // 民族
        address: "", // 住址
        workUnit: "", // 工作单位
        studentType: "", // 学员类型
        workingArea: "", // 工作区域
        birthday: "", // 出生年月
        idcardValidity: "", //身份证有效期
        idCardFront: "", // 身份证人像面
        idCardFrontPath: "", // 身份证人像面 key
        idCardBack: "", //身份证国徽面
        idCardBackPath: "", //身份证国徽面 key
        passportPhotoUrl:'', //外籍护照证件信息
passportPhoto:'', //外籍护照证件信息key
      },
      rules: {
        idType: [{ required: true, trigger: "blur", message: "请选择证件类型" }],
        userName: [{ required: true, trigger: "blur", message: "请输入姓名" }],
       
        mobile: [
          {
            required: true,
            validator: this.$validatePhone,
            trigger: "blur",
          },
        ],
        email: [
          { required: false, message: "请输入邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
      },
      dialogVisibleAudit: false,
      auditForm: {
        userId: null,
        audited: false,
        remark: null,
      },
      auditRules: {
        audited: [
          { required: true, trigger: "change" },
        ],
        remark: [
          {
            trigger: "blur",
            max: 200,
            message: "审核备注不能超过200个字"
          },
        ],
      },
      userId: "",
      education: [],
      authentication: [],
      companyList: [],
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: false,
      },
      nationList: [], // 民族数据
      studentTypeList: [], //学员类型
      disabledAuthentication: "",
      hString: "", // 指定编辑时 - 默认的手机号
      show1: false,
      EduUserCardList:[],
      authenticationDisable:false,
    };
  },
  created() {
    this.getEducationStatus();
    this.certificationStatus();
    this.getNationList();
    this.getstudengTypeList();
    this.getEduUserCardTypeList()
    this.getareatree();
    this.init();
  },
  computed: {},
  watch: {
    "baseform.mobile": {
      handler: function (val, oldVal) {
        if (this.hString && val && this.hString != val) {
          this.show1 = true;
        } else {
          this.show1 = false;
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    auditUser() {
      this.$refs['auditForm'].validate((valid) => {
        if (valid) {
          if (!this.auditForm.audited && !this.auditForm.remark) {
            this.$message({
              type: "error",
              message: "审核通过时备注不能为空",
            });
            return;
          }
          this.$post("/run/user/audit", this.auditForm).then((ret) => {
            if (ret.status == 0) {
              this.$message({
                type: "success",
                message: ret.message,
              });
              this.handleCloseAudit();
              setTimeout(() => {
                this.$router.push({
                  path: "/web/studentsListAudit",
                  query: {
                    refresh: true,
                  },
                });
              }, 1000);
            }
          });
        }
      });
    },
    handleCloseAudit() {
      this.dialogVisibleAudit = false;
      this.$refs["auditForm"].resetFields();
    },
    init() {
      this.userId = JSON.parse(this.$route.query.dataQuery).id;
      this.auditForm.userId = this.userId;
      this.authenticationDisable = JSON.parse(this.$route.query.dataQuery).authenticationDisable;
      console.log(this.authenticationDisable);
      this.queryData();
    },
    // 文化水平
    getEducationStatus() {
      this.$post("/sys/dictionary/key", {
        dictKey: "EDUCATION",
      })
        .then((res) => {
          if (res.status == "0") {
            this.education = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 查询公司下拉
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload/image/compress", formData)
        .then((result) => {
          this.baseform.headPathUrl = result.data.fileURL;
          this.baseform.headPath = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    //身份证人像面图片上传
    handleAvatarSuccessCardFront(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.baseform.idCardFront = result.data.fileURL;
          this.baseform.idCardFrontPath = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    //身份证国徽面图片上传
    handleAvatarSuccessCardBack(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.baseform.idCardBack = result.data.fileURL;
          this.baseform.idCardBackPath = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    //外籍护照证件信息
     handleAvatarSuccessPassportPhoto(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.baseform.passportPhotoUrl = result.data.fileURL;
          this.baseform.passportPhoto = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    // 上传证件照
    urltoBase64(res) {
      const that = this;
      const reader = new FileReader();
      reader.readAsDataURL(res.raw);
      reader.onload = function (e) {
        that.option.img = e.target.result;
      };
    },
    handleAvatarSuccessIdPhoto(res) {
      this.urltoBase64(res);
      if (this.opendialog) {
        this.dialogVisible = true;
      }
    },
    upload(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.baseform.idPhoto = result.data.fileURL;
          this.baseform.idPhotoKey = result.data.fileKey;
        })
        .catch((err) => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    beforeIdPhotoUpload(file) {
      this.opendialog = true;
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        let myFile = new window.File([data], this.baseform.userName + ".jpeg");
        this.upload({
          name: this.baseform.userName + ".jpeg",
          raw: myFile,
        });
      });
      this.dialogVisible = false;
    },
    docropperCencal() {
      // this.opendialog = false;
      this.dialogVisible = false;
    },
    delIdPhoto() {
      this.baseform.idPhoto = "";
      this.baseform.idPhotoKey = "";
    },
    // 认证状态
    certificationStatus() {
      this.$post("/sys/dictionary/key", {
        dictKey: "AUTHENTICATION",
      })
        .then((res) => {
          if (res.status == "0") {
            this.authentication = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    /* 民族 */
    getNationList() {
      this.$post("/sys/dictionary/key", {
        dictKey: "NATION",
      })
        .then((res) => {
          if (res.status == "0") {
            this.nationList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    /* 学员类型 */
    getstudengTypeList() {
      this.$post("/sys/dictionary/key", {
        dictKey: "STUDENTTYPE",
      })
        .then((res) => {
          if (res.status == "0") {
            this.studengTypeList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    /* 证件类型 */
    getEduUserCardTypeList() {
      this.$post("/sys/dictionary/key", {
        dictKey: "EDU_USER_CARD_TYPE",
      })
        .then((res) => {
          if (res.status == "0") {
            this.EduUserCardList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.companyList = [res.data];
      });
    },
    // 编辑保存
    doEditSave() {
      this.dialogVisibleAudit = true;
    },
    // 编辑查询
    queryData() {
      this.$post("/run/user/queryUser", { userId: this.userId })
        .then((res) => {
          const data = res.data || {};
          this.hString = data.mobile;
          this.baseform = {
            // ...this.baseform,
            idType:data.idType, //证件类型
            mobile: data.mobile, // 手机号
            email: data.email, // 邮件
            idcard: data.idcard || "", // 身份证
            userName: data.userName, // 用户名
            educationLevel: data.educationLevel, // 文化水平
            authentication: data.authentication, // 认证状态
            sex: data.sex, // 性别
            headPathUrl: data.headPathUrl, //图片
            headPath: data.headPath,
            compId: data.compId,
            idPhoto: data.idPhoto, // 证件照url
            idPhotoKey: data.idPhotoKey, // 证件照url
            nation: data.nation, //民族
            address: data.address,
            workUnit: data.workUnit,
            studentType: data.studentType ? data.studentType.toString() : "",
            workingArea: data.workingArea,
            idCardValidity: data.idCardValidity,
            birthday: data.birthday,
            idCardFront: data.idCardFrontUrl,
            idCardBack: data.idCardBackUrl,
            idCardFrontPath: data.idCardFront,
            idCardBackPath: data.idCardBack,
            passportPhoto:data.passportPhoto,
            passportPhotoUrl:data.passportPhotoUrl,
            department: data.department,
            
          };
          this.disabledAuthentication = data.authentication;
          if (data.idType== '10') {
                this.blurcard(data.idcard);
          }
        
          if (data.compId) {
            this.getCompany(data.compId);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    blurcard(e) {
      let sex = "";
      if (parseInt(e.substr(16, 1)) % 2 == 1) {
        //男
        sex = "1";
      } else {
        //女
        sex = "2";
      }
      this.baseform.sex = sex;
    },
    doCancel() {
      this.$router.push("/web/studentsListAudit");
    },
    /* 新增字段项 */
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    handleChange(val) {
      if (val == true) {
        this.monthShow = true;
      } else {
        this.monthShow = false;
      }
    },
    // 点击修改手机号
    xgmobile() {
      this.$post("/run/user/mobile/update", {
        userId: this.userId,
        mobile: this.baseform.mobile,
      })
        .then((res) => {
          if (res.status == "0") {
            this.show1 = false;
            this.queryData();
            this.$message({
              type: "success",
              message: "修改成功",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="less">
.cropper-content {
  .cropper {
    width: auto;
    height: 578px;
  }
}
.cropper_tip {
  background-color: #f1f7fd;
  padding: 15px 20px;
  h3 {
    margin-bottom: 15px;
  }
  .tip_index {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #5b6be7;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
  }
  .tip_text {
    min-width: 140px;
  }
}
//
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .el-cascader--small {
    line-height: 40px;
  }
}
.pic_upload {
  .el-upload {
    border: 0;
    display: block;
    max-height: 50px !important;
    text-align: left;
  }
}
.idPhoto {
  div {
    position: relative;
    display: flex;
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
.vueCropper_dialog {
  .el-upload {
    display: inline-block;
    border: 0;
    height: 16px !important;
  }
}
.PImgtitle {
  text-align: center;
  max-width: 286px;
}
</style>
<style lang="less">
.addlist {
  h3 {
    display: flex;
    border-left: 3px solid rgb(92, 107, 232);
    span {
      margin-left: 20px;
    }
    margin-bottom: 30px;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div {
    margin-bottom: 5px;
  }
  > div:last-child {
    border: 0;
    margin-bottom: 0;
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    width: 80%;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 80%;
  button {
    padding: 12px 30px;
  }
}
.tip {
  position: relative;
  z-index: 100;
  // width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px #eee;
}
.company {
  -webkit-input-placeholder {
    font-size: 12px;
  }
  -moz-placeholder {
    font-size: 12px;
  }
}
</style>